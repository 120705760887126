import React, { useEffect, useState } from "react";
import "./FindDoerModal.scss";
import { Button, Form, Modal } from "antd";
import { Doer, Doers } from "../../../Doers";
import { ValueOf } from "../../../../../utils/type-helpers";
import {
  FindDoerDetailsContent,
  FormValues,
} from "../../../../../components/FindDoerModalContent/FindDoerDetailsContent/FindDoerDetailsContent";
import { FindDoerSkillsContent } from "../../../../../components/FindDoerModalContent/FindDoerSkillsContent/FindDoerSkillsContent";
import {
  UseGetBusinessCitiesResp,
  UseGetBusinessSkillsResp,
  useGetCities,
  UseGetCitiesResp,
  useGetSkills,
  UseGetSkillsResp,
} from "../../../../../services/catalog";
import {
  UseGetBusinessDoerDetailsResp,
  UseGetBusinessDoersResp,
  useGetDoerDetails,
  UseGetDoerDetailsResp,
  useGetDoers,
  UseGetDoersResp,
} from "../../../../../services/doer";

interface IFindDoerModal {
  modalOpen: boolean;
  //eslint-disable-next-line
  setModalOpen: any;
  onAssignTask: (formValues: FormValues, doerId?: string) => void;
  assignTaskLoading: boolean;
  hasAdditionalDetails?: boolean;
  getCities?: () => UseGetCitiesResp | UseGetBusinessCitiesResp;
  getSkills?: () => UseGetSkillsResp | UseGetBusinessSkillsResp;
  getDoers?: (
    currentPage: number,
    pageSize: number,
    skillIds: Array<string | number>,
    cityId: number,
    enabledFetch?: boolean,
  ) => UseGetDoersResp | UseGetBusinessDoersResp;
  getDoerDetails?: (
    id?: number | string,
  ) => UseGetDoerDetailsResp | UseGetBusinessDoerDetailsResp;
}

export const FIND_DOER_MODAL_MODES = {
  SKILLS: "skills",
  DOERS: "doers",
  DOER: "doer",
  DETAILS: "details",
} as const;

export type FindDoerModalMode = ValueOf<typeof FIND_DOER_MODAL_MODES>;

export const FindDoerModal: React.FC<IFindDoerModal> = ({
  modalOpen,
  setModalOpen,
  onAssignTask,
  assignTaskLoading,
  hasAdditionalDetails = true,
  getCities = useGetCities,
  getSkills = useGetSkills,
  getDoers = useGetDoers,
  getDoerDetails = useGetDoerDetails,
}) => {
  const [mode, setMode] = useState<FindDoerModalMode>(
    FIND_DOER_MODAL_MODES.SKILLS,
  );
  const [selectedSkills, setSelectedSkills] = useState<Array<number | string>>(
    [],
  );
  const [doerId, setDoerId] = useState<string>();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!modalOpen) {
      setDoerId(undefined);
      setMode(FIND_DOER_MODAL_MODES.SKILLS);
      form.resetFields();
    }
  }, [modalOpen]);

  useEffect(() => {
    if (doerId) {
      setMode(FIND_DOER_MODAL_MODES.DOER);
    }
  }, [doerId]);

  const renderModalContent = () => {
    switch (mode) {
      case FIND_DOER_MODAL_MODES.DETAILS:
        return (
          <FindDoerDetailsContent
            form={form}
            onFinish={(formValues) => onAssignTask(formValues, doerId)}
            hasAdditionalDetails={hasAdditionalDetails}
          />
        );
      case FIND_DOER_MODAL_MODES.DOER:
        return (
          <div className={"doer-details-wrapper"}>
            <Doer doerId={doerId} getDoerDetails={getDoerDetails} />
          </div>
        );
      case FIND_DOER_MODAL_MODES.DOERS:
        return (
          <div className={"doers-details-wrapper"}>
            <Doers
              pageSize={3}
              onDoerClick={(id) => {
                setDoerId(id);
              }}
              defaultSelectedSkills={selectedSkills}
              getCities={getCities}
              getSkills={getSkills}
              getDoers={getDoers}
            />
          </div>
        );
      default:
        return (
          <FindDoerSkillsContent
            onSkillsSelect={setSelectedSkills}
            getSkills={getSkills}
          />
        );
    }
  };

  const renderFooterContent = () => {
    switch (mode) {
      case FIND_DOER_MODAL_MODES.DETAILS:
        return (
          <>
            <Button onClick={() => setMode(FIND_DOER_MODAL_MODES.DOER)}>
              უკან
            </Button>
            <Button
              loading={assignTaskLoading}
              onClick={form.submit}
              style={{
                backgroundColor: "#37A6F7",
                borderColor: "#37A6F7",
                color: "#FFF",
              }}
            >
              შემსრულებლის არჩევა
            </Button>
          </>
        );
      case FIND_DOER_MODAL_MODES.DOER:
        return (
          <>
            <Button onClick={() => setMode(FIND_DOER_MODAL_MODES.DOERS)}>
              უკან
            </Button>
            <Button onClick={() => setMode(FIND_DOER_MODAL_MODES.DETAILS)}>
              შემდეგი
            </Button>
          </>
        );
      case FIND_DOER_MODAL_MODES.DOERS:
        return (
          <Button onClick={() => setMode(FIND_DOER_MODAL_MODES.SKILLS)}>
            უკან
          </Button>
        );
      default:
        return (
          <Button
            disabled={!selectedSkills.length}
            onClick={() => setMode(FIND_DOER_MODAL_MODES.DOERS)}
          >
            შემდეგი
          </Button>
        );
    }
  };

  return (
    <Modal
      // width={ mode === "doer" || mode === "doers"  ? 700 : undefined}
      width={700}
      open={modalOpen}
      className={"find-doer-modal"}
      title={"შემსრულებლის მოძებნა"}
      footer={renderFooterContent()}
      onCancel={() => setModalOpen(false)}
      maskClosable
      destroyOnClose
    >
      <div className={"find-modal-content-wrapper"}>{renderModalContent()}</div>
    </Modal>
  );
};
