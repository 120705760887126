import { useState } from "react";

type Pagination = {
  currentPage: number;
  pageSize: number;
  pageSizeOptions: Array<number>;
  showSizeChanger?: boolean;
  onShowSizeChange?: (current: number, pageSize: number) => void;
  onChange?: (page: number) => void;
};

type TablePagination = {
  pagination: Pagination;
  resetPagination: () => void;
};

export const useTablePagination = (
  defaultPageSize?: number,
): TablePagination => {
  const initialPageSize = defaultPageSize ?? 10;
  const initialPagination: Pagination = {
    currentPage: 1,
    pageSize: initialPageSize,
    pageSizeOptions: [initialPageSize, 15, 20, 50],
    showSizeChanger: true,
  };

  const [pagination, setPagination] = useState<Pagination>(initialPagination);

  const resetPagination = () => {
    setPagination(initialPagination);
  };

  const onChange = (page: number) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const onShowSizeChange = (current: number, pageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize }));
  };

  return {
    pagination: { ...pagination, onShowSizeChange, onChange },
    resetPagination,
  };
};
