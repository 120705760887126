import React from "react";
import "./BusinessTaskDetails.scss";
import { useParams } from "react-router";
import { useGetBusinessTaskDetails } from "../../../../services/task";
import { Spin } from "antd";
import { IncomingBusinessTaskWrapper } from "./IncomingBusinessTaskWrapper";
import { BUSINESS_TASK_STATUS_TYPES } from "../../../../services/generalTypes";
import { AcceptedBusinessTaskWrapper } from "./AcceptedBusinessTaskWrapper";
import { BusinessTask } from "../BusinessTask/BusinessTask";

export const BusinessTaskDetails = () => {
  const { id } = useParams();
  const {
    data: businessTask,
    refetch,
    isLoading,
  } = useGetBusinessTaskDetails(id);

  const getTaskComponent = () => {
    if (businessTask) {
      switch (businessTask.status) {
        case BUSINESS_TASK_STATUS_TYPES.INCOMING:
          return (
            <IncomingBusinessTaskWrapper
              businessTask={businessTask}
              businessTaskId={id}
              refetchBusinessTask={refetch}
            />
          );
        case BUSINESS_TASK_STATUS_TYPES.ACCEPTED:
          return (
            <AcceptedBusinessTaskWrapper
              businessTask={businessTask}
              businessTaskId={id}
              refetchBusinessTask={refetch}
            />
          );
        default:
          return <BusinessTask businessTask={businessTask} />;
      }
    }
  };

  return (
    <div className={"business-task-details-container"}>
      <div
        className={`business-task-details-main-wrapper ${isLoading ? "loading" : ""}`}
      >
        {isLoading ? <Spin /> : getTaskComponent()}
      </div>
    </div>
  );
};
