import React, { useMemo } from "react";
import "./BusinessTask.scss";
import { BusinessTaskDetails } from "../../../../services/task";
import {
  BankOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
  SwapOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  formatPhoneNumber,
  getBusinessStatusColorByName,
  getBusinessStatusNameById,
} from "../../../../utils/helpers";
import { InfoDetailItem } from "../../../../components";
import moment from "moment";
import { SectionTitle } from "../../../../components";
import { businessTaskStatusTypes } from "../../../../services/generalTypes";

type BusinessTaskProps = {
  businessTask: BusinessTaskDetails;
  actionButtons?: JSX.Element;
};

export const BusinessTask: React.FC<BusinessTaskProps> = ({
  businessTask,
  actionButtons,
}) => {
  const statusColor = useMemo(() => {
    return getBusinessStatusColorByName(businessTask.status);
  }, [businessTask]);

  const statusName = useMemo(() => {
    const statusItem =
      businessTaskStatusTypes.find(
        (item) => item.name === businessTask.status,
      ) || businessTaskStatusTypes[0];
    return getBusinessStatusNameById(statusItem.id);
  }, [businessTask]);

  return (
    <div className={"business-task-details-wrapper"}>
      <div className={"business-task-info"}>
        <div>
          <div className={"company-name-wrapper"}>
            <div className={"company-name"}>{businessTask.company.name}</div>
            <SwapOutlined
              className="swap-icon"
              style={{ color: statusColor }}
            />
            <div style={{ color: statusColor }}>{statusName}</div>
          </div>
          <div className={"business-task-info-wrapper"}>
            <div className={"info-title"}>{businessTask.title}</div>
            <InfoDetailItem
              icon={<ClockCircleOutlined />}
              name={"შექმნის თარიღი (დღე/თვე/წელი)"}
              value={moment(businessTask.creationDate).format("DD/MM/YYYY")}
            />
            <InfoDetailItem
              icon={<EnvironmentOutlined />}
              name={"მისამართი"}
              value={businessTask.address}
            />

            <div className={"section"}>
              <SectionTitle title={"დავალების აღწერა"} />
              <div className={"section-content business-task-description"}>
                {businessTask.description}
              </div>
            </div>

            <div className={"section"}>
              <SectionTitle title={"კომპანიის ინფორმაცია"} />
              <div className={"section-content"}>
                <InfoDetailItem
                  icon={<BankOutlined />}
                  name={"სახელი"}
                  value={businessTask.company.name}
                />
                <InfoDetailItem
                  icon={<PhoneOutlined />}
                  name={"ნომერი"}
                  value={formatPhoneNumber(businessTask.company.number)}
                />
              </div>
            </div>

            <div className={"section"}>
              <SectionTitle title={"გამომგზავნის ინფორმაცია"} />
              <div className={"section-content"}>
                <InfoDetailItem
                  icon={<UserOutlined />}
                  name={"სახელი"}
                  value={`${businessTask.sender.name} ${businessTask.sender.surname}`}
                />
                <InfoDetailItem
                  icon={<MailOutlined />}
                  name={"ელ-ფოსტა"}
                  value={businessTask.sender.email}
                />
                <InfoDetailItem
                  icon={<PhoneOutlined />}
                  name={"ნომერი"}
                  value={formatPhoneNumber(businessTask.sender.phoneNumber)}
                />
              </div>
            </div>
          </div>
        </div>
        {actionButtons && (
          <div className={"action-buttons-wrapper"}>{actionButtons}</div>
        )}
      </div>
    </div>
  );
};
